export const PORTAL_NAME = {
  novonordisk: 'novonordisk',
  fticonsulting: 'fti',
  llamasoft: 'llamasoft',
  sika: 'sika',
  westpharma: 'westpharma',
  mackenzie: 'mackenzie',
  swissRe: 'swissre',
  merck: 'merck',
  avella: 'optum',
  nielsen: 'nielsen',
  lonza: 'lonza',
  devoteam: 'devoteam',
  kkwind: 'kkwindsolutions',
  iss: 'iss',
  cvc: 'cvc',
  cbre: 'cbre',
  manus: 'manus',
  implement: 'implement',
  genmab: 'genmab',
  brunswick: 'brunswick',
} as const;
